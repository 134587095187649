import { gsap } from 'gsap';

export default function fadeUpEffect(element) {
  document.querySelectorAll(element).forEach(function (ele) {
    gsap.from(ele, {
      scrollTrigger: {
        trigger: ele,
        start: 'top 90%',
      },
      duration: 0.5,
      opacity: '0',
      y: '20%',
    });
  });
}
