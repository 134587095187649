module.exports = {
  content: [
    './src/**/*.js',
    './src/**/*.jsx',
    './src/**/*.ts',
    './src/**/*.tsx',
  ],
  important: 'body', // https://tailwindcss.com/docs/configuration#important
  theme: {
    screens: {
      'xs-down': { max: '374px' },
      xs: '375px',
      'sm-down': { max: '639px' },
      sm: '640px',
      'md-down': { max: '767px' },
      md: '768px',
      'lg-down': { max: '1024px' },
      lg: '1025px',
      'xl-down': { max: '1279px' },
      xl: '1280px',
      '2xl': '1920px',
    },
    colors: {
      'current-color': 'currentColor',
      transparent: 'transparent',
      primary: 'var(--primary)',
      'primary-dark': 'var(--primary-dark)',
      'primary-light': 'var(--primary-light)',
      'primary-contrast': 'var(--primary-contrast)',
      secondary: 'var(--secondary)',
      'secondary-dark': 'var(--secondary-dark)',
      'secondary-light': 'var(--secondary-light)',
      'secondary-contrast': 'var(--secondary-contrast)',
      tertiary: 'var(--tertiary)',
      'tertiary-dark': 'var(--tertiary-dark)',
      'tertiary-light': 'var(--tertiary-light)',
      'tertiary-contrast': 'var(--tertiary-contrast)',
      black: 'var(--black)',
      white: 'var(--white)',

      'grey-1': 'var(--grey-1)',
      'grey-2': 'var(--grey-2)',
      'grey-3': 'var(--grey-3)',
      'grey-4': 'var(--grey-4)',
      'grey-5': 'var(--grey-5)',
      'grey-6': 'var(--grey-6)',
      'white-1': 'var(--white)',
    },
    extend: {
      spacing: {
        // spacing-xx corresponds to Figma [CloudKitchens] Brand & Website -> Components
        'spacing-01': '12px',
        'spacing-02': '16px',
        'spacing-03': '24px',
        'spacing-04': '32px',
        'spacing-05': '42px',
        'spacing-06': '48px',
        'spacing-07': '60px',
        'spacing-08': '80px',
        'spacing-09': '120px',
        'spacing-10': '160px',
        'spacing-11': '240px',
        xs: '.5rem',
        sm: '1rem',
        md: '1.25rem',
        lg: '1.5rem',
        xl: '2rem',
        '2xl': '3rem',
        '3xl': '4rem',
        '4xl': '9rem',
        '5xl': '12rem',
        '6xl': '18rem',
        '7xl': '25rem',
      },
      fontSize: {
        '2xl': '1.5rem',
      },
    },
  },
  safelist: [
    'bg-primary',
    'bg-primary-contrast',
    'bg-primary-dark',
    'bg-primary-light',
    'bg-secondary',
    'bg-secondary-contrast',
    'bg-secondary-dark',
    'bg-secondary-light',
    'bg-tertiary',
    'bg-tertiary-contrast',
    'bg-tertiary-light',
    'bg-tertiary-dark',
    'text-primary',
    'text-primary-contrast',
    'text-primary-dark',
    'text-primary-light',
    'text-secondary',
    'text-secondary-contrast',
    'text-secondary-dark',
    'text-secondary-light',
    'text-tertiary',
    'text-tertiary-contrast',
    'text-tertiary-light',
    'text-tertiary-dark',
  ],
  variants: {},
  corePlugins: {
    preflight: false,
  },
  plugins: [],
};
