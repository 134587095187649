import { useMixpanel as useGatsbyMixpanel } from 'gatsby-plugin-mixpanel';

type MixpanelEvent = {
  event: string;
  product: string;
  component: string;
};

// Following Otter convention from op-mixpanel-client
type MixpanelStandardizedEvent = `${string} | ${string} | ${string}`;

const createMixpanelEventString = ({
  product,
  component,
  event,
}: MixpanelEvent): MixpanelStandardizedEvent =>
  `${product} | ${component} | ${event}`;

type Mixpanel = ReturnType<typeof useGatsbyMixpanel>;

class MixpanelClient {
  private _mixpanel: Mixpanel;
  constructor(mixpanel: Mixpanel) {
    this._mixpanel = mixpanel;
  }

  public track(
    event: MixpanelEvent,
    properties: Record<string, unknown> | undefined = undefined
  ) {
    this._mixpanel.track(createMixpanelEventString(event), properties);
  }
}

export const useMixpanel = (): MixpanelClient => {
  const mixpanel = useGatsbyMixpanel();
  return new MixpanelClient(mixpanel);
};
