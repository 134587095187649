import {
  FacilityFinderConfigProvider,
  OP_FACILITY_FINDER_PROJECT,
  PreloadedTranslationsProvider,
} from '@csscompany/op-facility-finder';
import { createClient } from '@prismicio/client';
import { PrismicProvider } from '@prismicio/react';
import { getUrl, isBrowser } from '@publicss/utils';
import React, { useEffect, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import styled from 'styled-components';

import { CONFIG } from '../constants/mapConfig';
import { useCKReducer, useSiteLang } from '../hooks/useCKReducer';
import { useFetchClient } from '../hooks/useFetchClient';
import { useUTMCookies } from '../hooks/useUTMCookies';
import ScriptsResolver from '../multisite-config/ScriptsResolver';
import { getCSDSTheme } from '../multisite-config/getCSDSTheme';
import { getTheme } from '../multisite-config/getTheme';
import { PageContextProvider } from '../providers/PageContext';
import ButtonsStyles from '../styles/Buttons';
import FormStyles from '../styles/Form';
import GlobalStyles from '../styles/GlobalStyles';
import { facilityFinderUrl } from '../utils';
import Footer from './Footer';
import Nav from './Nav';

const ContentStyles = styled.div<{ isRTL: boolean }>`
  margin-top: 68px;
  --line-in-pct: ${({ isRTL }) => (isRTL ? '101%' : '-101%')};
  --line-out-pct: ${({ isRTL }) => (isRTL ? '-101%' : '101%')};
  @keyframes line-in {
    0% {
      transform: translate3d(var(--line-in-pct), 0, 0);
    }
    to {
      transform: translateZ(0);
    }
  }
  @keyframes line-out {
    0% {
      transform: translateZ(0);
    }
    to {
      transform: translate3d(var(--line-out-pct), 0, 0);
    }
  }
  .btn--txt--out {
    ::before {
      animation: 0.25s linear 0s line-out forwards;
    }
  }
`;

const Layout = ({ children, pageContext }) => {
  const {
    moduleData,
    siteTag,
    global,
    siteLang: pageLang,
    navCTAData,
  } = pageContext;

  if (isBrowser()) {
    if (!sessionStorage.getItem('landing_url'))
      sessionStorage.setItem('landing_url', window.location.href);
  }

  useEffect(() => {
    const btns = document.querySelectorAll('.btn--text');
    function handleMouseOver(this: HTMLElement) {
      this?.classList.add('btn--txt--out');
    }
    btns.forEach((btn) => {
      btn.addEventListener('mouseover', handleMouseOver);
    });
    return () => {
      btns.forEach((btn) => {
        btn.removeEventListener('mouseover', handleMouseOver);
      });
    };
  }, [children, pageContext]);

  const initialState = { siteLang: pageContext.siteLang };
  const { state, dispatch } = useCKReducer(initialState);
  const theme = getTheme(siteTag ?? 'site:cloudkitchens');
  const csdsTheme = getCSDSTheme(theme);
  const queryClient = new QueryClient();
  const utm = useUTMCookies();

  const isRTL = pageContext?.siteLang
    ? ['ar', 'he'].includes(pageContext.siteLang.split('-')[0].toLowerCase())
    : false;

  return (
    <QueryClientProvider client={queryClient}>
      <PageContextProvider
        {...pageContext}
        csdsTheme={csdsTheme}
        appState={state}
        appDispatch={dispatch}
        utm={utm}>
        <FacilityFinderContextProvider pageContext={pageContext}>
          <LanguageSetter pageLang={pageLang}>
            <GlobalStyles colors={theme.colors} />
            <ButtonsStyles />
            <FormStyles />
            <ContentStyles
              isRTL={isRTL}
              dir={isRTL ? 'rtl' : 'auto'}
              id="bm-scroll-container"
              className="wrapper">
              {global && moduleData?.navData && (
                <Nav url={getUrl()} pageContext={pageContext} />
              )}
              <main>{children}</main>
              {global && moduleData?.footerData && <Footer />}
            </ContentStyles>
            <ScriptsResolver />
          </LanguageSetter>
        </FacilityFinderContextProvider>
      </PageContextProvider>
    </QueryClientProvider>
  );
};

export default Layout;

const LanguageSetter: React.FunctionComponent<any> = ({
  pageLang,
  children,
}) => {
  const { siteLang, setSiteLang } = useSiteLang();

  React.useEffect(() => {
    if (siteLang !== pageLang) setSiteLang(pageLang);
  }, [pageLang, siteLang, setSiteLang]);

  return children;
};

const DEFAULT_TRANSLATIONS = {
  '': {
    en: {},
  },
};

const FacilityFinderContextProvider = ({ pageContext, children }) => {
  const {
    siteTag,
    siteName,
    siteLang,
    siteLangs,
    prismicApiKey,
    translationsMap,
  } = pageContext;
  const fetchClient = useFetchClient();
  const prismicClient = createClient('cloudkitchens-main', {
    accessToken: prismicApiKey,
  });

  const translations = useMemo(() => {
    if (!translationsMap || !(siteLang in translationsMap)) {
      console.warn(`No translations found for ${siteLang}`);
      return DEFAULT_TRANSLATIONS;
    }
    return translationsMap[siteLang];
  }, [siteLang, translationsMap]);

  return (
    <PreloadedTranslationsProvider
      project={OP_FACILITY_FINDER_PROJECT.project}
      preloadedTranslationsData={translations}>
      <PrismicProvider client={prismicClient}>
        <FacilityFinderConfigProvider
          mapboxToken={CONFIG.MAPBOX_TOKEN}
          mapboxStyle={CONFIG.MAP_STYLE}
          siteName={siteName}
          siteLang={siteLang}
          siteTags={[siteTag]}
          isCk={true}
          facilityFinderPath={facilityFinderUrl(siteLang, siteLangs, siteTag)}
          fetchClient={fetchClient}>
          {children}
        </FacilityFinderConfigProvider>
      </PrismicProvider>
    </PreloadedTranslationsProvider>
  );
};
