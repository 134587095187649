import { LightTheme } from '@csscompany/csds-themes';
import { merge } from 'lodash';

import { Theme } from './getTheme';

export const getCSDSTheme = (theme: Theme) => {
  const { colors } = theme;

  const csdsThemePartial = {
    colors: {
      $interactiveFillPrimaryEnabled: colors.primary,
      $interactiveFillPrimaryHover: colors['primary-dark'],
      $interactiveFillPrimaryPressed: colors['primary-dark'],
      $interactiveFillPrimaryDisabled: `${colors.primary}88`,
      $interactiveFillPrimaryOnPrimary: colors['primary-contrast'],
      $interactiveFillPrimaryOnDisabled: colors['primary-contrast'],
      $interactiveFillSecondaryEnabled: colors['grey-3'],
      $interactiveFillSecondaryHover: '#C3C1AD',
      $interactiveFillSecondaryPressed: colors['tertiary-light'],
      $interactiveFillSecondaryDisabled: colors['grey-6'],
      $interactiveFillSecondaryOnSecondary: colors['tertiary-contrast'],
      $interactiveFillSecondaryOnDisabled: '#525252',
      // $interactiveFillTertiaryEnabled: string;
      // $interactiveFillTertiaryHover: string;
      // $interactiveFillTertiaryPressed: string;
      // $interactiveFillTertiaryDisabled: string;
      // $interactiveFillTertiaryOnTertiary: string;
      // $interactiveFillTertiaryOnDisabled: string;
      $interactiveFillInverseEnabled: colors['grey-2'],
      $interactiveFillInverseHover: colors['grey-3'],
      $interactiveFillInversePressed: '#C3C1AD',
      $interactiveFillInverseDisabled: colors['grey-2'],
      $interactiveFillInverseOnInverse: '#525252',
      $interactiveFillInverseOnDisabled: '#D6D6D6',
      $interactiveFillDangerEnabled: colors.secondary,
      $interactiveFillDangerHover: colors['secondary-dark'],
      $interactiveFillDangerPressed: colors['secondary-dark'],
      // $interactiveFillDangerDisabled: string;
      // $interactiveFillDangerOnDanger: string;
      // $interactiveFillDangerOnDisabled: string;
      $interactiveOverlayPrimaryEnabled: `${colors.primary}00`,
      $interactiveOverlayPrimaryHover: `${colors.primary}0F`,
      $interactiveOverlayPrimaryPressed: `${colors.primary}1F`,
      $interactiveOverlayPrimarySelectedEnabled: `${colors.primary}14`,
      $interactiveOverlayPrimarySelectedHover: `${colors.primary}24`,
      $interactiveOverlayPrimarySelectedPressed: `${colors.primary}2E`,
      // $interactiveOverlaySecondaryEnabled: '#18171200',
      // $interactiveOverlaySecondaryHover: '#1817120D',
      // $interactiveOverlaySecondaryPressed: '#18171214',
      // $interactiveOverlaySecondarySelectedEnabled: '#1817120F',
      // $interactiveOverlaySecondarySelectedHover: '#18171217',
      // $interactiveOverlaySecondarySelectedPressed: '#1817121F',
      // $interactiveOverlayInverseEnabled: string;
      // $interactiveOverlayInverseHover: string;
      // $interactiveOverlayInversePressed: string;
      // $interactiveOverlayInverseSelectedEnabled: string;
      // $interactiveOverlayInverseSelectedHover: string;
      // $interactiveOverlayInverseSelectedPressed: string;
      $interactiveOutlinePrimaryEnabled: `${colors.primary}3D`,
      $interactiveOutlinePrimaryHover: `${colors['primary-dark']}7A`,
      $interactiveOutlinePrimaryPressed: '#18201DB8',
      $interactiveOutlinePrimaryDisabled: `${colors.primary}29`,
      $interactiveOutlinePrimarySelectedEnabled: `${colors.primary}F5`,
      $interactiveOutlinePrimarySelectedHover: `${colors['primary-dark']}F5`,
      $interactiveOutlinePrimarySelectedPressed: '#18201DF5',
      $interactiveOutlineSecondaryEnabled: '#18171247',
      // $interactiveOutlineSecondaryHover: string;
      // $interactiveOutlineSecondaryPressed: string;
      // $interactiveOutlineSecondaryDisabled: string;
      // $interactiveOutlineSecondarySelectedEnabled: string;
      // $interactiveOutlineSecondarySelectedHover: string;
      // $interactiveOutlineSecondarySelectedPressed: string;
      // $interactiveOutlineInverseEnabled: string;
      // $interactiveOutlineInverseHover: string;
      // $interactiveOutlineInversePressed: string;
      // $interactiveOutlineInverseDisabled: string;
      // $interactiveOutlineInverseSelectedEnabled: string;
      // $interactiveOutlineInverseSelectedHover: string;
      // $interactiveOutlineInverseSelectedPressed: string;
      // $interactiveOutlineDangerEnabled: string;
      // $interactiveOutlineDangerHover: string;
      // $interactiveOutlineDangerPressed: string;
      // $interactiveOutlineDangerDisabled: string;

      $staticBackgroundStandard: colors['grey-2'],
      $staticBackgroundStrong: '#2B403A14',
    },
    fonts: {
      $display:
        "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
      $text:
        "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
      $mono:
        "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
    },
    typography: {
      $displayLarge: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $displayMedium: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $displaySmall: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $headingLarge: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $headingMedium: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $headingSmall: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $headingXsmall: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $labelLarge: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $labelLargeBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $labelMedium: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $labelMediumBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $labelSmall: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $labelSmallBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $paragraphLarge: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $paragraphLargeBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $paragraphMedium: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $paragraphMediumBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $paragraphSmall: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $paragraphSmallBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $numberLarge: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $numberLargeBold: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $numberMedium: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $numberMediumBold: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $numberSmall: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $numberSmallBold: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $numberXsmall: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $numberXsmallBold: {
        mobile: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
        desktop: {
          fontFamily:
            "'Roobert', ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
        },
      },
      $captionMedium: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $captionMediumBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $captionSmall: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
      $captionSmallBold: {
        mobile: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        desktop: {
          fontFamily:
            "'Roobert', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
      },
    },
  };

  const mergedTheme = merge(LightTheme, csdsThemePartial);

  return mergedTheme;
};
