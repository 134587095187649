// Taken from entities GlobalStyles
const entitiesSharedColors = {
  white: '#ffffff',
  'grey-1': '#FAFAFA',
  'grey-2': '#F2F2F2',
  'grey-3': '#D3D3D3',
  'grey-4': '#B1B1B1',
  'grey-5': '#777777',
  'grey-6': '#3D3D3D',
  black: '#000000',
};

export default entitiesSharedColors;
