import { isBrowser } from '@publicss/utils';
import { useEffect, useState } from 'react';

function getMatches(query: string): boolean {
  // Prevents SSR issues
  return isBrowser() ? window.matchMedia(query).matches : false;
}

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(getMatches(query));
  const handleChange = () => setMatches(getMatches(query));

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    matchMedia.addEventListener('change', handleChange);

    // Remove listener on unmount
    return () => matchMedia.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
}
