import React from 'react';

export * from './IconMobileMenu';

export function IconPlusMinus() {
  return (
    <svg
      width="26px"
      height="26px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        className="icon-plus--rect"
        stroke="var(--main)"
        x="0.5"
        y="0.5"
        width="25"
        height="25"
        rx="12.5"></rect>
      <polygon
        className="icon-plus--plus"
        fill="var(--main)"
        fillRule="nonzero"
        points="16.664 12.168 13.496 12.168 13.496 9 12.152 9 12.152 12.168 9 12.168 9 13.512 12.152 13.512 12.152 16.664 13.496 16.664 13.496 13.512 16.664 13.512 16.664 12.168"></polygon>
      <polygon
        className="icon-plus--minus"
        fill="var(--main)"
        fillRule="nonzero"
        points="13.496 9 12.152 9 12.152 16.664 13.496 16.664"></polygon>
    </svg>
  );
}

export function IconDropdown() {
  return (
    <svg
      className="directional-arrows"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.29826 8.34745L15.1077 8.36018L15.1204 15.1696L13.8222 15.1696L13.8731 10.5621L9.02375 15.4114L8.05643 14.4441L12.9058 9.59478L8.29826 9.6457L8.29826 8.34745Z"
        fill="#3D3D3D"
      />
    </svg>
  );
}

export function IconArrowUpRight() {
  return (
    <svg
      className="directional-arrows"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.298257 0.347449L7.10769 0.360177L7.12042 7.16961L5.82218 7.16962L5.87309 2.56211L1.02375 7.41145L0.0564272 6.44412L4.90576 1.59478L0.298257 1.6457L0.298257 0.347449Z"
        fill="#C74741"
      />
    </svg>
  );
}

export function IconTwitter() {
  return (
    <svg width="16" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.39 3.317v.437c0 4.19-3.32 8.817-9.359 8.817-1.911 0-3.622-.349-5.031-1.222.302 0 .503.088.805.088 1.51 0 3.019-.524 4.126-1.397-1.409 0-2.617-.96-3.12-2.183.202 0 .403.087.604.087.302 0 .704 0 1.006-.087-1.61-.35-2.515-1.659-2.515-3.23 0 .262.704.35 1.207.436-.905-.61-1.409-1.57-1.409-2.619 0-.61.101-1.134.403-1.571 1.61 1.92 3.924 3.143 6.641 3.317v-.785C7.748 1.659 9.258 0 11.07 0c.906 0 1.811.611 2.415 1.222a10.38 10.38 0 002.114-.785c-.202.698-.806 1.396-1.41 1.746A7.094 7.094 0 0016 1.659c-.402.698-1.006 1.222-1.61 1.658z"
        fill="#71706A"
      />
    </svg>
  );
}

export function IconFacebook() {
  return (
    <svg width="9" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.382 7.071l.333-2.357h-3V3.38c0-.708.75-1.022 1.75-1.022h.75V.08S7.132 0 6.215 0c-1.917 0-3 1.1-3 3.143v1.571h-2.5v2.357h2.5v5.5h2.5v-5.5h2.667z"
        fill="#71706A"
      />
    </svg>
  );
}

export function IconLinkedIn() {
  return (
    <svg width="15" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.524 1.366c0 .755-.626 1.367-1.4 1.367-.772 0-1.398-.612-1.398-1.367C.726.612 1.352 0 2.125 0s1.399.612 1.399 1.366zm.011 2.46H.715v8.744h2.82V3.826zm4.502 0H5.235v8.744h2.803V7.98c0-2.552 3.4-2.761 3.4 0v4.59h2.814V7.033c0-4.306-5.033-4.15-6.215-2.03V3.826z"
        fill="#71706A"
      />
    </svg>
  );
}
