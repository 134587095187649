export default {
  colors: {
    primary: '#2B403A',
    'primary-dark': '#1F2D26',
    'primary-light': '#385D51',
    'primary-contrast': '#FFFFFF',
    secondary: '#E16847',
    'secondary-dark': '#B1482C',
    'secondary-light': '#FC8A69',
    'secondary-contrast': '#FFFFFF',
    tertiary: '#807B67',
    'tertiary-dark': '#323023',
    'tertiary-light': '#B9B49E',
    'tertiary-contrast': '#000000',
    white: '#FCFBF7',
    'grey-1': '#FFFEF5',
    'grey-2': '#F8F6EB',
    'grey-3': '#E4E2D4',
    'grey-4': '#B9B49E',
    'grey-5': '#807B67',
    'grey-6': '#323023',
    black: '#1F1F1D',
    'accent-1': '#E0D9BF',
  },
  fonts: {},
};
