import themeChefcollective from './themes/chefcollective';
import themeCitychefs from './themes/citychefs';
import themeCloudkitchens from './themes/cloudkitchens';
import themeCocinasOcultas from './themes/cocinas-ocultas';
import themeCookcity from './themes/cookcity';
import themeCooklane from './themes/cooklane';
import themeDishmix from './themes/dishmix';
import themeEverplate from './themes/everplate';
import themeFoodstars from './themes/foodstars';
import themeFreshlane from './themes/freshlane';
import themeKitchenCentral from './themes/kitchen-central';
import themeKitchenBase from './themes/kitchenbase';
import themeKitchenconnect from './themes/kitchenconnect';
import themeKitchennow from './themes/kitchennow';
import themeKitchenpark from './themes/kitchenpark';
import themeKitchenplus from './themes/kitchenplus';
import themeKitchenValley from './themes/kitchenvalley';
import themePedidioslab from './themes/pedidioslab';
import themePopKitchens from './themes/pop-kitchens';
import themeProfoodSolutions from './themes/profood-solutions';
import themeSmartcitykitchens from './themes/smartcity-kitchens';
import themeVirtualKitchens from './themes/virtual-kitchens';

export type Theme = typeof themeCloudkitchens;

const siteThemes: Record<string, Theme> = {
  'site:chefcollective': themeChefcollective,
  'site:citychefs': themeCitychefs,
  'site:cloudkitchens': themeCloudkitchens,
  'site:cocinas-ocultas': themeCocinasOcultas,
  'site:cookcity': themeCookcity,
  'site:cooklane': themeCooklane,
  'site:dishmix': themeDishmix,
  'site:everplate': themeEverplate,
  'site:foodstars': themeFoodstars,
  'site:freshlane': themeFreshlane,
  'site:kitchen-central': themeKitchenCentral,
  'site:kitchenbase': themeKitchenBase,
  'site:kitchenconnect': themeKitchenconnect,
  'site:kitchennow': themeKitchennow,
  'site:kitchenpark': themeKitchenpark,
  'site:kitchenplus': themeKitchenplus,
  'site:kitchenvalley': themeKitchenValley,
  'site:pedidoslab': themePedidioslab,
  'site:pop-kitchens': themePopKitchens,
  'site:profood-solutions': themeProfoodSolutions,
  'site:smartcity-kitchens': themeSmartcitykitchens,
  'site:virtual-kitchens': themeVirtualKitchens,
};

export const getTheme = (siteTag: string): Theme => {
  const theme = siteThemes[siteTag];
  if (!theme) throw new Error(`Theme not found for siteTag: ${siteTag}`);
  return theme;
};
