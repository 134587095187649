export const BUSINESS_MANAGER_NAV_HEIGHT = 60;

export const ACTION_BAR_HEIGHT = 64;

//TODO: https://phabricator.citystoragesystems.com/T19203 store x/y positions as ratio instead.
export const OLD_LP_WIDTH = 820;
export const OLD_LP_HEIGHT = 523;
export const NEW_LP_WIDTH = 690;

export const FULL_SCREEN_WIZARD_STEPS_BREAKPOINT = '$7';

export const LOG_PRODUCT_NAME = 'Cloud Kitchens';
export const LANDING_PAGE_COMPONENT = 'Landing Page';
export const FACILITY_FINDER_COMPONENT = 'Facility Finder';
export const FACILITY_DETAILS_COMPONENT = 'Facility Details';
export const FORM_FOOTER_COMPONENT = 'Form Footer';
export const BOOK_TOUR_COMPONENT = 'Book Tour';

export const DATA_TEST_IDS = {
  LANDING_PAGE_TEMPLATE: 'landing-page_template',
  KG_PAGE: 'kg-page_template',
  LOADING_SPINNER: 'loading-spinner',
};

export const LOCATION_LIST_ID = 'location-container';
export const DETAIL_NAV_HOLDER_ID = 'nav-holder';
export const DETAIL_GALLERY_ID = 'otter-app-grid-gallery';
export const DETAIL_DETAIL_INFO_ID = 'otter-app-grid-detail_info';
export const DETAIL_NEAR_BY_ID = 'otter-app-grid-neary-by';
export const DETAIL_LEAD_FORM_ID = 'detail-lead-form';
export const LOCATIONS_COMPONENT_NAME = 'Locations Page';
export const LOCATIONS_DETAILS_COMPONENT_NAME = 'Details Page';
export const LOCATIONS_TEST_ID = 'locations_box';
export const BM_SCROLL_CONTAINER_ID = 'bm-scroll-container';
