import { Script } from 'gatsby';
import React from 'react';

const LatamScripts = (): JSX.Element => {
  const isVirtualKitchens = false;

  return (
    <div>
      {isVirtualKitchens && (
        <>
          <Script>
            {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                      n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '496412464279085');
                  fbq('track', 'PageView');
                  `}
          </Script>
          <noscript>
            {`
              <img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=496412464279085&ev=PageView&noscript=1"
              />
              `}
          </noscript>
          <Script type="text/javascript">
            {`
                  window._mfq = window._mfq || [];
                  (function() {
                  var mf = document.createElement("script");
                  mf.type = "text/javascript"; mf.async = true;
                  mf.src = "//cdn.mouseflow.com/projects/3decda73-816d-42ca-abc7-fba3e0350898.js";
                  document.getElementsByTagName("head")[0].appendChild(mf);
                  })();
                  `}
          </Script>
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-697894898"
          />
          <Script>
            {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'AW-697894898');
                  `}
          </Script>
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-139148060-6"
          />
          <Script>
            {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'UA-139148060-6', { 'optimize_id': 'GTM-MQJDJ6C'});
                  `}
          </Script>
          <Script type="text/javascript">
            {`
                  _linkedin_partner_id = "2695476";
                  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                  `}
          </Script>
          <Script type="text/javascript">
            {`
              (function(){var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);})();
              `}
          </Script>
          <noscript>
            {`
              <img
                height="1"
                width="1"
                style="display:none;"
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=2695476&fmt=gif"
              />
              `}
          </noscript>
          <Script type="text/javascript">
            {`
                  window._tfa = window._tfa || [];
                  window._tfa.push({notify: 'event', name: 'page_view', id: 1378673});
                  !function (t, f, a, x) {
                  if (!document.getElementById(x)) {
                  t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
              }
              }(document.createElement('script'),
                  document.getElementsByTagName('script')[0],
                  '//cdn.taboola.com/libtrc/unip/1378673/tfa.js',
                  'tb_tfa_script');
                  `}
          </Script>
        </>
      )}
    </div>
  );
};

export default LatamScripts;
