import { Reducer, useCallback, useReducer } from 'react';

import { usePageContext } from '../providers/PageContext';

interface CKState {
  siteLang: string;
}

interface CKAction {
  type: keyof CKState;
  payload: CKState[keyof CKState];
}

const defaultState: CKState = {
  siteLang: '',
};

function reducer(state: CKState, { type, payload }: CKAction) {
  return {
    ...state,
    [type]: payload,
  };
}

/** Initializes a new state object. Should only be called in Layout. */
export function useCKReducer(
  initialState: Partial<CKState> = {} as Partial<CKState>
) {
  const [state, dispatch] = useReducer<Reducer<CKState, CKAction>>(reducer, {
    ...defaultState,
    ...initialState,
  });
  return { state, dispatch };
}

// Example: Creating a hook for a specific state property
export function useSiteLang() {
  const {
    appDispatch,
    appState: { siteLang },
  } = usePageContext();
  const setSiteLang = useCallback(
    (payload: CKState['siteLang']) =>
      appDispatch({ type: 'siteLang', payload }),
    [appDispatch]
  );
  return {
    siteLang,
    setSiteLang,
  };
}
