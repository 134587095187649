import React from 'react';

interface SlideDotProps {
  fill: string;
  opacity: number;
}

export const SlideDot = ({ fill, opacity }: SlideDotProps) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill={fill}
    opacity={opacity}
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="4" />
  </svg>
);
