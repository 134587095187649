import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';

import { repositoryConfigs } from './prismic-previews';
import './src/assets/fonts/material-symbols-rounded.css';
import './src/assets/fonts/roobert.css';
import Layout from './src/components/Layout';
import './src/css/index.css';

gsap.registerPlugin(ScrollTrigger);

export function wrapPageElement({ element, props }) {
  return (
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <Layout {...props}>{element}</Layout>
    </PrismicPreviewProvider>
  );
}
