exports.linkResolver = (doc) => {
  if (doc.type === 'page') {
    if (doc.data.slug[0] !== '/') {
      return `/${doc.data.slug}`;
    }
    return `${doc.data.slug}`;
  } else if (doc.type === 'landing_page') {
    return `/locations/${doc.data.slug}/`;
  }
  return '/';
};
