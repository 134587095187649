import {
  AppProvider,
  ComponentTokenProvider,
  ComponentTokenOverrides,
} from '@csscompany/csds-components';
import type { SystemTheme } from '@csscompany/csds-themes';
import get from 'lodash/get';
import React, { createContext, useContext } from 'react';

export const PageContext = createContext<object>({});
export const PageContextConsumer = PageContext.Consumer;
export const usePageContext = () =>
  useContext<Record<string, any>>(PageContext);

function useTranslate({ lang, translations, defaultLang }) {
  return (key) => {
    const text =
      get(translations, [lang, key]) || get(translations, [defaultLang, key]);

    if (text) return text;

    throw new Error(`Missing translation for ["${lang}", "${key}"]`);
  };
}

function CSDSProvider({ theme, tokens, children }) {
  return theme ? (
    <AppProvider globalStylesheets={false} theme={theme}>
      {tokens ? (
        <ComponentTokenProvider tokens={tokens}>
          {children}
        </ComponentTokenProvider>
      ) : (
        children
      )}
    </AppProvider>
  ) : (
    children
  );
}

interface PageContextProviderProps {
  langKey: string;
  defaultLang: string;
  translations: object;
  children: React.ReactNode;
  csdsTheme?: SystemTheme;
  csdsTokens?: ComponentTokenOverrides;
  typeformProps?: {
    id: string;
    transitiveSearchParams?: string[];
    hidden?: Record<string, string>;
    size?: number;
  };
}

export const PageContextProvider: React.FC<PageContextProviderProps> = ({
  langKey,
  defaultLang,
  translations,
  children,
  csdsTheme,
  csdsTokens,
  ...rest
}) => {
  const translate =
    translations && langKey
      ? useTranslate({ lang: langKey, translations, defaultLang })
      : undefined;

  return (
    <CSDSProvider theme={csdsTheme} tokens={csdsTokens}>
      <PageContext.Provider value={{ ...rest, translate, langKey }}>
        {children}
      </PageContext.Provider>
    </CSDSProvider>
  );
};

export default PageContext;
