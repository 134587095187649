import { createDefaultFetchClient } from '@csscompany/op-facility-finder';
import type { FetchClient } from '@csscompany/op-facility-finder';

import { CONFIG } from '../constants/apiConstants';

const appConfig = { name: 'ck', version: '1.0.0' };

const fetchClient = createDefaultFetchClient({
  appConfig,
  baseUrl: CONFIG.API_URL,
});

export const useFetchClient = (): FetchClient => {
  return fetchClient;
};
