/**
 * Customize the facility finder path, e.g. /locations, /zh/locations, /it/localita
 * For single language sites, don't add lang to the url, or for Foodstars in English
 * For multi-language sites, prefix the URL with the language.
 * @param currentLang
 * @param siteLangs
 * @param siteTag
 * @returns
 */
const facilityFinderUrl = (
  currentLang: string,
  siteLangs: string[],
  siteTag: string
): string => {
  // for multi-lang sites, get the language for the url e.g. /en or /zh
  let langSlug =
    siteLangs && siteLangs.length > 1 ? `/${currentLang.split('-')[0]}` : '';
  // If this is for Foodstars in English, don't use /en
  if (siteTag === 'site:foodstars' && currentLang === 'en-gb') langSlug = '';
  // If this is for KitchenPark in Arabic, keep the -sa or -kw
  if (
    siteTag === 'site:kitchenpark' &&
    (currentLang === 'ar-sa' ||
      currentLang === 'ar-kw' ||
      currentLang === 'ar-ae')
  )
    langSlug = `/${currentLang}`;
  // default to /locations, perhaps we could eventually fetch this from prismic.
  return `${langSlug}/locations`;
};

export default facilityFinderUrl;
