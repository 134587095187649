import { CaretDown } from '@publicss/icons';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import * as React from 'react';
import styled from 'styled-components';

import { useIsRTL } from '../hooks/useIsRTL';
import { usePageContext } from '../providers/PageContext';

// The Intl.DisplayNames API does not always return the language name we want to use, this list will override
const EXCEPTIONS = {
  id: 'Bahasa',
};

export const fmtLangKey = (lang: string, siteLangs: string[]) => {
  const langKey = lang.split('-')[0];
  const hasMultipleDialects = siteLangs
    .filter((l) => l !== lang)
    .some((l) => langKey === l.split('-')[0]);
  if (hasMultipleDialects) return lang;
  return langKey;
};

const getDisplayName = (lang, siteLangs) =>
  EXCEPTIONS[lang] ??
  new Intl.DisplayNames([lang], {
    type: 'language',
  }).of(fmtLangKey(lang, siteLangs));

const LanguagePicker = () => {
  const [languagePopperOpen, setLanguagePopperOpen] = React.useState(false);
  const { siteLangs, siteLang } = usePageContext();
  const isRTL = useIsRTL();
  const siteLangKeys = React.useMemo(
    () => siteLangs?.map((lang) => fmtLangKey(lang, siteLangs)),
    [siteLangs]
  );

  if (siteLangKeys.length < 2) return null;

  const menuLangs = siteLangKeys?.filter(
    (lang) => lang !== fmtLangKey(siteLang, siteLangs)
  );
  const buttonDisplayName = getDisplayName(siteLang, siteLangs);
  EXCEPTIONS[siteLang] ??
    new Intl.DisplayNames([siteLang], {
      type: 'language',
    }).of(fmtLangKey(siteLang, siteLangs));

  const toggleLanguagePopper = () => {
    setLanguagePopperOpen((prev) => !prev);

    const directionalSvgs =
      document.getElementsByClassName('directional-arrows');
    const rotate = isRTL
      ? ['rotate-[275deg]', 'rtl-hover', '!me-0', '!ms-auto']
      : ['!me-0', '!ms-auto'];

    if (directionalSvgs.length > 0) {
      Array.from(directionalSvgs).forEach((element) => {
        rotate.forEach((el) => {
          element.classList.add(el);
        });
      });
    }
  };

  return (
    <LangPickerStyles>
      <div
        className={classNames(
          'nav--dropdown--trigger-alt navLangs relative list-item md:flex',
          {
            'nav--dropdown--click': languagePopperOpen,
          }
        )}
        role="button"
        aria-label="Toggle language menu open"
        tabIndex={0}
        aria-haspopup="menu"
        onKeyDown={(e) => {
          if (e.code === 'Space') {
            e.preventDefault();
            toggleLanguagePopper();
          }
        }}
        onClick={toggleLanguagePopper}>
        <a className="lang--nav--mobile btn--text btn--text--language ms-0 whitespace-nowrap ps-1 rtl:pe-1 md:me-5 md:ms-5">
          {buttonDisplayName}
          <CaretDown />
        </a>

        <ul
          className={classNames(
            'nav--dropdown-alt nav--dropdown--language-alt bg--white absolute divide-y !px-5 !py-2',
            {
              hidden: !languagePopperOpen,
            }
          )}>
          {menuLangs?.map((lang) => {
            // const langLink = currentPath.includes('/blog/')
            //   ? `/${lang}/`
            //   : currentPath.replace(`/${siteLang}/`, `/${lang}/`);
            // const langLink =
            //   lang === primarySiteLang ? '/' : `/${lang.split('-')[0]}/`;

            // TODO: Slugs are inconsistent between site languages, since they're sometimes localized.
            //       Additionally, language routes are not consistent between sites,
            //       some sites put the primary language at /, others at /{langKey}/
            //       This is a simple solution for now, but we could direct to a corresponding
            //       page in the future.
            const langLink = `/${fmtLangKey(lang, siteLangs)}/`;

            // PN: Leaving this here in case we need a flag emoji
            // const getFlagEmoji = (countryCode) =>
            //   String.fromCodePoint(
            //     ...[...countryCode.toUpperCase()].map(
            //       (x) => 0x1f1a5 + x.charCodeAt()
            //     )
            //   );

            const displayName = getDisplayName(lang, siteLangs);

            return (
              <li key={lang} className="!p-0">
                <Link
                  role="menuitem"
                  kind="ani"
                  cover
                  skipLocalize
                  to={langLink}
                  direction="up"
                  duration={1}
                  bg="#FAFAFA"
                  className="relative flex gap-x-4 py-2">
                  {displayName} <IconDropdown />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </LangPickerStyles>
  );
};

const LangPickerStyles = styled.div`
  .nav--dropdown--trigger-alt {
    & > a > svg {
      display: inline-block;
      position: relative;
      top: auto;
      inset-inline-end: auto;
      margin-inline-start: 4px;
      transition: all 0.3s ease;
      transform: rotate(180deg);
    }
  }
  .nav--dropdown--click {
    .nav--dropdown-alt {
      display: block;
      height: 150px;
      opacity: 1;
    }
    .nav--dropdown--language-alt {
      height: auto;
    }
    & > a > svg {
      transform: rotate(0deg);
    }
  }
  .nav--dropdown-alt {
    display: block;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 0;
    top: 17px;
    width: 210px;
    opacity: 0;
    a {
      font-size: 14px;
      font-weight: normal;
      line-height: 170%;
      letter-spacing: 0.02em;
      text-transform: none;
      &:hover {
        color: #000;
        svg {
          transform: translate(3px, -3px);
        }
        .rtl-hover {
          transform: rotate(275deg) translate(3px, -3px) !important;
        }
      }
      svg {
        transition: all 0.2s ease;
        top: 8px;
        inset-inline-end: 0;
      }
    }
  }
  .nav--dropdown--language-alt {
    inset-inline-start: auto;
    inset-inline-end: 0;
    top: 20px;
  }
`;

function IconDropdown() {
  return (
    <svg
      className="directional-arrows"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.29826 8.34745L15.1077 8.36018L15.1204 15.1696L13.8222 15.1696L13.8731 10.5621L9.02375 15.4114L8.05643 14.4441L12.9058 9.59478L8.29826 9.6457L8.29826 8.34745Z"
        fill="#3D3D3D"
      />
    </svg>
  );
}

/**
 * Lifted from Emea entities site
 */
const Link: React.FunctionComponent<any> = ({
  to,
  kind = 'a',
  skipLocalize = false,
  ...otherProps
}) => {
  function getComponent(kind) {
    switch (kind) {
      case 'ani':
        return AniLink;
      case 'gatsby':
        return GatsbyLink;
      case 'a':
      default:
        return ({ to, ...props }) => <a href={to} {...props} />;
    }
  }

  // Router
  function localizeHref(to) {
    const { langs, langKey } = usePageContext();
    if (!langs || langs.length < 2) return to;
    if (to[0] === '/' && to.indexOf(`/${langKey}`) !== 0)
      return `/${langKey}${to}`;

    return to;
  }

  const Component = getComponent(kind);
  const href = skipLocalize ? to : localizeHref(to);

  return <Component to={href} {...otherProps} />;
};

export default LanguagePicker;
