import React from 'react';

import { usePageContext } from '../providers/PageContext';

/**
 * This hook can only be called within PageContextProvider
 */
export const useIsRTL = () => {
  const pageContext = usePageContext();
  const isRTL = React.useMemo(() => {
    return ['ar', 'he'].includes(
      pageContext.siteLang.split('-')[0].toLowerCase()
    );
  }, [pageContext]);
  return isRTL;
};
