import { asText } from '@prismicio/helpers';
import padStart from 'lodash/padStart';
import { DOMParser } from 'xmldom';

import toBoolean from './toBoolean';

// Function to add "" around each element in the given string
export const addQuotes = (str) => {
  const words = str.split(', ');
  return words.map((w) => `"${w}"`);
};

export function secondsToMinutes(seconds) {
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds - m * 60);
  const pad0 = (n) => padStart(n.toString(), 2, '0');

  return `${pad0(m)}:${pad0(s)}`;
}

export const sanitizeDescription = (description) => {
  const doc = new DOMParser().parseFromString(
    description.replace(/(\r\n|\n|\r)/gm, ''),
    'text/html'
  );

  return doc.documentElement.textContent.replace('Read More…', '');
};

export const hasRichText = (text) => {
  // Handle passing the richText array itself
  if (Array.isArray(text)) {
    return toBoolean(asText(text));
  }

  return toBoolean(text?.richText && asText(text.richText));
};
