import ckTheme from './cloudkitchens';
import entitiesSharedColors from './entitiesSharedColors';

const mono = '#000000';
const monoContrast = '#ffe600';
const monoDark = '#000000';
const monoLight = '#ffe600';

export default {
  colors: {
    ...ckTheme.colors,
    primary: mono,
    'primary-dark': monoDark,
    'primary-light': monoLight,
    'primary-contrast': monoContrast,
    secondary: mono,
    'secondary-dark': monoDark,
    'secondary-light': monoLight,
    'secondary-contrast': monoContrast,
    tertiary: mono,
    'tertiary-dark': monoDark,
    'tertiary-light': monoLight,
    'tertiary-contrast': monoContrast,
    'accent-1': monoLight,

    ...entitiesSharedColors,
  },
  fonts: {},
};
