import pick from 'lodash/pick';

import isBrowser from './isBrowser';

export function getUrl() {
  return isBrowser() ? location.pathname : '';
}

export function getUrlDomain() {
  return isBrowser() ? location.host : '';
}

export function getUrlParam(key) {
  if (!isBrowser()) return undefined;

  const params = new URLSearchParams(location.search);
  return params.get(key);
}

export function getUrlParams(keys = []) {
  if (!isBrowser()) return undefined;

  const params = new URLSearchParams(location.search);
  const allParams = Object.fromEntries(params.entries());

  return keys.length > 0 ? pick(allParams, keys) : allParams;
}

export function getUrlParamFromSessionStorage(key) {
  if (!isBrowser()) return undefined;

  const url = sessionStorage.getItem('landing_url') || location.search;
  const params = new URLSearchParams(url);
  return params.get(key);
}

export function getUrlHash() {
  return isBrowser() ? location.hash : undefined;
}

export function getReferrer() {
  return window._locations ? window._locations.at(-1) : document.referrer;
}

export const getFullUrl = () => (isBrowser() ? location.href : undefined);
