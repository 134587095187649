import { getUrl } from '@publicss/utils';
import { Script } from 'gatsby';
import React from 'react';

type ScriptsProps = {
  siteTag?: string;
};

const ApacScripts: React.FC<ScriptsProps> = ({ siteTag }) => {
  const isBookitEnabled = [
    'site:chefcollective',
    'site:freshlane',
    'site:kitchenbase',
    'site:kitchennow',
    'site:smartcity-kitchens',
  ].includes(siteTag);

  const isThankYouPage = [
    '/en/thank-you/',
    '/thank-you/',
    '/zh/thank-you/',
  ].includes(getUrl());

  return (
    <>
      <Script src="https://cdn.polygraph.net/pg.js" />
      {isBookitEnabled && isThankYouPage && (
        <Script>{`
          var _ld_scriptEl = document.createElement('script');
          _ld_scriptEl.src = 'https://cdn.leandata.com/js-snippet/ld-book-v2.js';
          _ld_scriptEl.addEventListener('load', function () {
            LDBookItV2.initialize('00Df4000003jvM8EAI', 'New Prospect', 'ld_bookit_log_id');
            LDBookItV2.setFormProvider('typeform');
            if (LDBookItV2.validateTempUID()) {
              LDBookItV2.saveFormDataFromURLParams();
              LDBookItV2.submit();
            }
          });
          document.body.appendChild(_ld_scriptEl);
        `}</Script>
      )}
      {isBookitEnabled && !isThankYouPage && (
        <Script>{`
          var orgId = '00Df4000003jvM8EAI';
          var hiddenFieldName = 'ld_bookit_log_id';
          (function (orgId, hiddenFieldName) {
            let hiddenUID = ''+orgId+'_'+Date.now()+'_'+Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
            let forms = document.querySelectorAll('[ld-name="typeform"]');
            for (const form of forms) {
              form.setAttribute('data-tf-hidden', hiddenFieldName+'='+hiddenUID);
            }
            window.localStorage.setItem('LDBookItV2_tempSavedUID', hiddenUID);
          })(orgId, hiddenFieldName);
        `}</Script>
      )}
    </>
  );
};

export default ApacScripts;
