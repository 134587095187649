import * as React from 'react';
import { forwardRef } from 'react';

const SvgCaretDown = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      d="M3.821.095.488 3.294l.634.61 2.699-2.412L6.52 3.905l.634-.611L3.821.095Z"
      fill="#fff"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgCaretDown);
export default ForwardRef;
