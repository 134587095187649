import ckTheme from './cloudkitchens';
import entitiesSharedColors from './entitiesSharedColors';

const mono = '#549B70';
const monoContrast = '#FFFFFF';
const monoDark = '#17593D';
const monoLight = '#A6F7B8';

export default {
  colors: {
    ...ckTheme.colors,
    primary: mono,
    'primary-dark': monoDark,
    'primary-light': monoLight,
    'primary-contrast': monoContrast,
    secondary: mono,
    'secondary-dark': monoDark,
    'secondary-light': monoLight,
    'secondary-contrast': monoContrast,
    tertiary: mono,
    'tertiary-dark': monoDark,
    'tertiary-light': monoLight,
    'tertiary-contrast': monoContrast,
    'accent-1': monoLight,

    ...entitiesSharedColors,
  },
  fonts: {},
};
