import { useLocation } from '@reach/router';
import { Script } from 'gatsby';
import React, { useEffect } from 'react';

import { isAnyFacilityPage } from '../utils/kitchen-growth-helpers';

// CHAT BOT DEPRECATED, WE ARE USING QUALIFIED INSTEAD!!!!
export const ChatBot = () => {
  const location = useLocation();

  useEffect(() => {
    const showBot = !isAnyFacilityPage() ? 'visible' : 'hidden';

    if (document.getElementsByClassName('roko-instabot-widget-button')[0])
      document.getElementsByClassName(
        'roko-instabot-widget-button'
      )[0].style.visibility = showBot;

    document.querySelectorAll('.roko-instabot-widget').forEach((element) => {
      element.style.visibility = showBot;
    });
  }, [location.pathname]);

  return (
    <Script
      strategy="idle"
      type="text/javascript"
      src="https://widget.instabot.io/jsapi/rokoInstabot.js"
      crossOrigin>
      apiKey: "sYqF7blHxUSjaAgD8gqZb0pUjP5O3ki3n7+eGjZ/qss="
    </Script>
  );
};
