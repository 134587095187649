import { Box, Card, Button, Icon } from '@csscompany/csds-components';
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useIsMobile, useIsDesktop } from '../../utils/breakpoints';
import { SlideDot } from '../icons/SlideDot';

type FacilityDetails = {
  renders: any;
  slug: string;
  sfdc: string;
  brand_name: {
    text: string;
  };
  meta_description: {
    text: string;
  };
};

interface MoreFacilitiesNearbyProps {
  title?: string;
  description?: string;
  facilities: Array<FacilityDetails>;
}

const CARDS_PER_CAROUSEL_SCROLL = 4;
const MAX_FACILITIES_PER_CAROUSEL = 17;

export const FacilityCarousel = ({
  title,
  description,
  facilities,
}: MoreFacilitiesNearbyProps) => {
  const cards = facilities?.slice(0, MAX_FACILITIES_PER_CAROUSEL) || [];
  const containerRef = useRef<HTMLDivElement | null>(null);

  const cardRefs = useRef<HTMLElement[]>([]);
  const white = `var(--grey-2)`;

  // currentIndex is the index of the left-most fully visible card
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState<boolean>(false);

  useEffect(() => {
    cardRefs.current = cardRefs.current.slice(0, cards.length);
  }, [cards]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        for (const entry of entries) {
          const entryIndexStr = entry.target.getAttribute('data-index');
          if (entryIndexStr) {
            const entryIndex = parseInt(entryIndexStr);
            // An earlier entry has just been scrolled into view
            if (entry.isIntersecting && entryIndex < currentIndex) {
              setCurrentIndex(entryIndex);
            }
            // Current entry has just been scrolled out of view
            if (entryIndex === currentIndex && !entry.isIntersecting) {
              setCurrentIndex(currentIndex + 1);
            }
            // Final entry is scrolled into view
            if (entryIndex === cards.length - 1) {
              setIsScrolledToEnd(
                entry.isIntersecting && entry.intersectionRatio === 1
              );
            }
          }
        }
      },
      { threshold: [0, 1], root: containerRef.current }
    );

    const observedEls: HTMLElement[] = [];
    if (cardRefs.current.length > 0) {
      observedEls.push(cardRefs.current[currentIndex]);
      if (currentIndex - 1 >= 0) {
        observedEls.push(cardRefs.current[currentIndex - 1]);
      }
      if (cards.length - 1 !== currentIndex) {
        observedEls.push(cardRefs.current[cards.length - 1]);
      }
    }

    observedEls.map((el) => el && observer.observe(el));
    return () => {
      observedEls.map((el) => el && observer.unobserve(el));
    };
  }, [currentIndex, cards.length]);

  // scrollToPrevious and scrollToNext use scrollTo instead of scrollIntoView
  // because we only want to scroll horizontally, not vertically.

  const scrollToPrevious = useCallback(() => {
    containerRef.current?.scrollTo({
      left:
        cardRefs.current[currentIndex - CARDS_PER_CAROUSEL_SCROLL]
          ?.offsetLeft || cardRefs.current[0]?.offsetLeft,
      behavior: 'smooth',
    });
  }, [currentIndex]);

  const scrollToNext = useCallback(() => {
    containerRef.current?.scrollTo({
      left:
        cardRefs.current[currentIndex + CARDS_PER_CAROUSEL_SCROLL]
          ?.offsetLeft || cardRefs.current[15]?.offsetLeft,
      behavior: 'smooth',
    });
  }, [currentIndex]);

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  if (cards.length === 0) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        marginBottom="$6"
        justifyContent="space-between"
        alignItems="center">
        <div>
          <h4 className="h4 color--white mb-5 text-4xl md:mb-1 md:text-2xl">
            {title?.length ? title : 'Explore facilities'}
          </h4>
          <div className="richtext--holder color--grey-4 text-base md:text-sm">
            {description?.length
              ? description
              : 'Pay only for space you need, compared to 2000 ft2 at a traditional restaurant and get cooking in weeks, not months.'}
          </div>
        </div>
        <Box
          display={isDesktop ? 'grid' : 'none'}
          columnGap="$3"
          gridTemplateColumns="1fr 1fr">
          <CarouselButton
            onClick={scrollToPrevious}
            emphasis="medium"
            variant="secondary"
            disabled={currentIndex === 0}>
            <Icon icon="chevron_left" color="white" />
          </CarouselButton>
          <CarouselButton
            onClick={scrollToNext}
            emphasis="medium"
            variant="secondary"
            disabled={
              isScrolledToEnd || cards.length <= CARDS_PER_CAROUSEL_SCROLL
            }>
            <Icon icon="chevron_right" />
          </CarouselButton>
        </Box>
      </Box>
      <Carousel
        ref={containerRef}
        overflowX="auto"
        overflowY="hidden"
        display="grid"
        columnGap="$4"
        // Allow part of the last card to peek out
        gridTemplateColumns={
          isMobile
            ? {
                _: `repeat(${cards.length}, 80%)`,
                $6: `repeat(${cards.length}, 75%)`,
              }
            : isDesktop
              ? {
                  _: `repeat(${cards.length}, 60%)`,
                  $6: `repeat(${cards.length}, 25%)`,
                }
              : {
                  _: `repeat(${cards.length}, 60%)`,
                  $6: `repeat(${cards.length}, 40%)`,
                }
        }
        // Set so that offsetLeft is calculated relative to this element
        position="relative">
        {cards.map((card: FacilityDetails, index: number) => (
          <Link
            to={`/locations/${card.slug}`}
            key={index}
            ref={(el) => el && (cardRefs.current[index] = el)}
            data-index={index}>
            <StyledCard
              body={
                <NormalizedCardContainer className="richtext--holder color--grey-4">
                  {card.meta_description.text}
                </NormalizedCardContainer>
              }
              header={
                <Card.Header
                  hero={
                    <Card.Hero
                      alt={`${card.brand_name.text} facility image`}
                      aspectRatio={2.02}
                      src={
                        card.renders?.document?.data?.images[0]?.image_url.url
                      }
                    />
                  }
                  title={
                    <NormalizedCardContainer>
                      <div className="eyebrow color--white mb-3">
                        {card.sfdc}
                      </div>

                      <h5 className="h5 color--white">
                        {card.brand_name.text}
                      </h5>
                    </NormalizedCardContainer>
                  }
                />
              }></StyledCard>
          </Link>
        ))}
      </Carousel>
      <Box margin="auto" justifyContent="center" display="flex">
        <Box
          width="68px"
          textAlign="center"
          display="grid"
          position="absolute"
          gridTemplateColumns="1fr 1fr 1fr 1fr">
          <Box textAlign="center">
            <SlideDot
              fill={white}
              opacity={currentIndex < CARDS_PER_CAROUSEL_SCROLL ? 1 : 0.3}
            />
          </Box>
          <Box textAlign="center">
            <SlideDot
              fill={white}
              opacity={
                currentIndex >= CARDS_PER_CAROUSEL_SCROLL && currentIndex < 8
                  ? 1
                  : 0.3
              }
            />
          </Box>
          <Box textAlign="center">
            <SlideDot
              fill={white}
              opacity={currentIndex >= 8 && currentIndex < 12 ? 1 : 0.3}
            />
          </Box>
          <Box textAlign="center">
            <SlideDot fill={white} opacity={currentIndex >= 12 ? 1 : 0.3} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const CarouselButton = styled(Button)`
  border-radius: 3em;
  height: 32px;
  width: 32px;
  border-color: var(--white);
`;

const Carousel = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCard = styled(Card)`
  background: transparent;
  box-shadow: none;
  height: 100%;
  padding-bottom: 14px;
  border-radius: 0;
  border: 1px solid transparent;

  :hover {
    cursor: pointer;
  }
`;

const NormalizedCardContainer = styled.div`
  margin: 0 0 -8px -16px;
`;
