import tailwindConfig from '../../tailwind.config';

const { screens } = tailwindConfig.theme;

export const media = {
  xsDown: `(max-width: ${screens['xs-down'].max})`,
  xsUp: `(min-width: ${screens.xs})`,
  smDown: `(max-width: ${screens['sm-down'].max})`,
  smUp: `(min-width: ${screens.sm})`,
  mdDown: `(max-width: ${screens['md-down'].max})`,
  mdUp: `(min-width: ${screens.md})`,
  lgDown: `(max-width: ${screens['lg-down'].max})`,
  lgUp: `(min-width: ${screens.lg})`,
  xlDown: `(max-width: ${screens['xl-down'].max})`,
  xlUp: `(min-width: ${screens.xl})`,
  '2xlUp': `(min-width: ${screens['2xl']})`,
};
